@import url(https://fonts.googleapis.com/css?family=Tangerine|Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  color: #000;
}

.landingText {
  position: center;
  flex-direction: row;
  font-family: 'Tangerine', cursive;
  font-size: 4em;
  padding: 5em 0em 9em 0em;
}

.minorText {
  font-size: 30%;
  font-family: 'Playfair Display', serif;
  color: gray;
}

/* Mobile Devices */

@media only screen and (max-width: 768px) {
  .landingText {
    font-size: 40px;
    padding: 9em 0em 9em 0em;
  }
}

h1 {
  text-shadow: 1px 1px 2px #535353;
}

.about-me {
  text-align: left;
}

.jumbotron {
  font-size: 20px;
  overflow: hidden;
}

.img-thumbnail {
  float: left;
  margin: 5px 20px;
}

.contact {
  color: black;
  /* text-decoration: dotted; */
  border-bottom: grey 1px dotted;
}

.pr-2 {
  color: #424242;
}
.langs {
  max-width: 100px;
  min-width: 75px;
  align-self: center;
  padding-bottom: 25px;
}

a {
  color: #0056b3;
}

/* a {
  color: black;
  text-decoration: dotted;
  border-bottom: grey 0.125em solid;
} */

